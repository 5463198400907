import { clearPcnValidationResponseContext } from "../../contexts/app-context";
import { PayPcnWizardStep } from "../../enums/PayPcnWizardStep";
import FormButtons from "../../components/FormButtons";

const PayByCheque = (props: { stepUpdate: (value: PayPcnWizardStep) => void }) => {
  return (
    <div className="pcn-form">
      <div className="pcn-info">
        <h2>Payment by telephone</h2>
        <p>
          Payment can be made on the automated payment line <b>020 3553 4559</b>. No handling charge applies. Payment can be made by using
          all major debit and credit cards exept American Express.
        </p>
      </div>
      <div className="pcn-info">
        <h2>Payment by cheque</h2>
        <p>Payment by cheque, including a ??2.50 handling charge, should be sent to:</p>
        <ul className="pcn-address">
          <li>Parking Charge Notice Department</li>
          <li>30 Dorset Square</li>
          <li>London</li>
          <li>NW1 6QJ</li>
        </ul>
        <p>Please note:</p>
        <ul>
          <li>Cash payments are not accepted</li>
          <li>
            Cheques should be made payable to <b>Euro Car Parks Ltd</b> with the Parking Charge Notice Reference Number, your Vehicle
            Registration Number, the location of the car park where you parked and the date the Parking Charge Notice was issued on the
            reverse.
          </li>
          <li>A handling charge of ??2.50 applies to all cheque payments and must be added to the amount due.</li>
        </ul>
        <p>Euro Car Parks may refuse payment if:</p>
        <ul>
          <li>You fail to enclose the exact sum</li>
          <li>Your cheque is post dated</li>
          <li>You fail to enclose the payment slip</li>
        </ul>
      </div>
      <FormButtons
        isProcessing={false}
        previousStep={() => props.stepUpdate(PayPcnWizardStep.Ok)}
        nextStep={() => {
          clearPcnValidationResponseContext();
          props.stepUpdate(PayPcnWizardStep.Verification);
        }}
        nextText="Start Again"
      ></FormButtons>
    </div>
  );
};

export default PayByCheque;
