import { useContext } from "react";
import { Form } from "reactstrap";
import { AppContext, clearPcnValidationResponseContext } from "../../contexts/app-context";
import { PayPcnWizardStep } from "../../enums/PayPcnWizardStep";
import FormButtons from "../../components/FormButtons";
import FormRowView from "../../components/FormRowView";
import { PaymentMethod } from "../../enums/PaymentMethod";

const PaySuccess = (props: { stepUpdate: (value: PayPcnWizardStep) => void }) => {
  const context = useContext(AppContext);

  return (
    <div>
      <Form action="" method="" className="pcn-form">
        <div className="pcn-info no-print">
          <h2>Your payment has been processed</h2>
          <p>Thank you for paying online. Your payment has been processed successfully and your parking charge notice is now closed.</p>
        </div>

        {context.pcnPaymentResponse.transactionId && (
          <FormRowView label="Transaction ID" value={context.pcnPaymentResponse.transactionId} />
        )}
        {context.pcnPaymentResponse.amountAuthorized !== null && (
          <FormRowView label="Transaction amount" value={"??" + context.pcnPaymentResponse.amountAuthorized.toFixed(2)} />
        )}
        {context.pcnPaymentResponse.pcn && <FormRowView label="Reference (PCN)" value={context.pcnPaymentResponse.pcn} />}

        {context.pcnPaymentResponse.paymentMethod === PaymentMethod.Card && (
          <>
            {context.pcnPaymentResponse.ccNumberMasked && (
              <FormRowView label="Card number" value={context.pcnPaymentResponse.ccNumberMasked} />
            )}
            {context.pcnPaymentResponse.ccExpiry && <FormRowView label="Card expiry" value={context.pcnPaymentResponse.ccExpiry} />}
            {context.pcnPaymentResponse.authCode && <FormRowView label="Authorisation code" value={context.pcnPaymentResponse.authCode} />}
          </>
        )}
        <div className="no-print">
          <FormButtons
            isProcessing={false}
            hidePrevious={true}
            nextStep={() => {
              clearPcnValidationResponseContext();
              props.stepUpdate(PayPcnWizardStep.Verification);
            }}
            previousText="Print"
            nextText="Start Again"
          ></FormButtons>
        </div>
      </Form>
    </div>
  );
};

export default PaySuccess;
