import { createContext } from "react";
import { IAppeal } from "../models/IAppeal";
import { IPcnPaymentResponse } from "../models/IPcnPaymentResponse";
import { IPcnValidationResponse } from "../models/IPcnValidationResponse";
import { PaymentMethod } from "../enums/PaymentMethod";

export interface AppState {
  pcnValidationResponse: IPcnValidationResponse;
  pcnPaymentResponse: IPcnPaymentResponse;
  appeal: IAppeal;
}

const defaultState: AppState = {
  pcnValidationResponse: {
    debtId: 0,
    pcnCode: "",
    vrmCode: "",
    siteId: "",
    convCode: "",
    chargeAmt: 0,
    pcnPaid: false,
    pcnDate: "",
    pcnTime: "",
    pcnCancelled: false,
    appealExists: false,
    appealAllowed: true,
    isPopla: false,
    withThirdPartyDebt: false,
    thirdPartyAgentId: 0,
    thirdPartyAgent: "",
    isAlreadyValidated: false,
    enableMerchantEmailReceipt: false,
  },

  pcnPaymentResponse: {
    pcn: "",
    status: 0,
    responseText: "",
    responseDetail: "",
    authCode: "",
    transactionId: "",
    avsResponse: "",
    cvvResponse: "",
    amountAuthorized: 0,
    ccNumberMasked: "",
    ccExpiry: "",
    paymentMethod: PaymentMethod.Other,
  },

  appeal: {
    accountId: 0,
    email: "",
    iAmTheDriver: false,
    iAmTheId: 0,
    iAmThe: "",
    name: "",
    address: "",
    town: "",
    county: "",
    postcode: "",
    homeTelephone: "",
    mobileTelephone: "",
    workTelephone: "",
    reason: "",
    evidenceFiles: [],
    appealSubmitted: false,
    filesSubmitted: [],
    processCompleted: false,
  },
};

const AppContext = createContext(defaultState);

const AppProvider = (props: any) => {
  return <AppContext.Provider value={defaultState}>{props.children}</AppContext.Provider>;
};

const clearPcnValidationResponseContext = () => {
  defaultState.pcnValidationResponse.debtId = 0;
  defaultState.pcnValidationResponse.pcnCode = "";
  defaultState.pcnValidationResponse.vrmCode = "";
  defaultState.pcnValidationResponse.convCode = "";
  defaultState.pcnValidationResponse.chargeAmt = 0;
  defaultState.pcnValidationResponse.pcnPaid = false;
  defaultState.pcnValidationResponse.pcnDate = "";
  defaultState.pcnValidationResponse.pcnTime = "";
  defaultState.pcnValidationResponse.pcnCancelled = false;
  defaultState.pcnValidationResponse.appealExists = false;
  defaultState.pcnValidationResponse.appealAllowed = true;
  defaultState.pcnValidationResponse.isPopla = false;
  defaultState.pcnValidationResponse.withThirdPartyDebt = false;
  defaultState.pcnValidationResponse.thirdPartyAgentId = 0;
  defaultState.pcnValidationResponse.isAlreadyValidated = false;
};

export { AppContext, AppProvider, clearPcnValidationResponseContext };
