import { AppealWizardStep } from "../../enums/AppealWizardStep";
import AppealNotValidResponse from "./AppealNotValidResponse";

const AppealNotAllowed = (props: { stepUpdate: (value: AppealWizardStep) => void }) => {
  const handleStepUpdate = (value: AppealWizardStep) => {
    props.stepUpdate(value);
  };

  return (
    <AppealNotValidResponse
      headerText="Appeal closed"
      bodyText="Please refer to the appeal response you have received previously for this Parking Charge Notice."
      stepUpdate={handleStepUpdate}
    ></AppealNotValidResponse>
  );
};

export default AppealNotAllowed;
