import { useContext } from "react";
import { AppContext } from "./contexts/app-context";
import { Route, Routes } from "react-router-dom";
import PayPcnWizard from "./views/PcnPayment/PayPcnWizard";
import AppealWizard from "./views/Appeal/AppealWizard";
import "./App.css";

function App() {
  const context = useContext(AppContext);

  return (
    <div className="main-container">
      <AppContext.Provider value={context}>
        <Routes>
          <Route path="/pay-a-parking-charge" element={<PayPcnWizard />} />
          <Route path="/appeal-a-parking-charge" element={<AppealWizard />} />
        </Routes>
      </AppContext.Provider>
    </div>
  );
}

export default App;
