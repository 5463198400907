export enum DebtRecoveryAgent {
  ControlAccount = 1,
  GeorgeWalker = 2,
  DRPL = 3,
  RX = 4,
  GordonAndNoble = 8,
  SCS = 10,
  BWL = 11,
  CST = 12,
  EuroParkingCollections = 14,
  ZZPS_L = 19,
  ZZPS_DWL = 22,
  ZZPS_DNL = 24,
  DCBL = 25,
  DCBL_L = 28, // note: this will fail in staging as the value is 26 (28 in production), until both values match (to be done by Easycollect).
}
