export enum PayPcnWizardStep {
  Verification,
  Cancelled,
  ThirdParty,
  Ok,
  PayOnline,
  PayCheque,
  PaySuccess,
  NotProcessed
}
