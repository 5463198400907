import { useContext } from "react";
import { Form, FormGroup, Col, Label, Input, FormFeedback  } from "reactstrap";
import { AppealWizardStep } from "../../enums/AppealWizardStep";
import { useFormik } from "formik";
import { EcpApiMethod } from "../../enums/ApiMethod";
import { AppContext } from "../../contexts/app-context";
import { IAppealResponse } from "../../models/IAppealResponse";
import { AppealResponse } from "../../enums/AppealResponse";
import useApiRequest from "../../hooks/useApiRequest";
import FormButtons from "../../components/FormButtons";
import AlertMessage from "../../components/AlertMessage";
import FormRowView from "../../components/FormRowView";
import * as Yup from "yup";
import * as api from "../../api/api-urls";

const AppealReview = (props: { stepUpdate: (value: AppealWizardStep) => void }) => {
  const context = useContext(AppContext);

  const { apiRequest, isProcessing, error, setError, errorMessage, setErrorMessage } = useApiRequest();

  const validationSchema = Yup.object().shape({
    privacyStatement: Yup.bool().oneOf([true], "You must accept the Privacy Statement"),
  });

  const resetContext = () => {
    context.appeal.accountId = 0;
    context.appeal.email = "";
    context.appeal.iAmTheDriver = false;
    context.appeal.iAmTheId = 0;
    context.appeal.iAmThe = "";
    context.appeal.name = "";
    context.appeal.address = "";
    context.appeal.town = "";
    context.appeal.county = "";
    context.appeal.postcode = "";
    context.appeal.homeTelephone = "";
    context.appeal.mobileTelephone = "";
    context.appeal.workTelephone = "";
    context.appeal.reason = "";
    context.appeal.evidenceFiles = [];
    context.appeal.appealSubmitted = false;
    context.appeal.filesSubmitted = [];
    context.appeal.processCompleted = false;
  };

  const { handleSubmit, handleChange, touched, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      allowReplay: false,
      privacyStatement: false,
    },
    validationSchema,
    onSubmit(values) {
      (async () => {
        try {
          const formData = new FormData();

          formData.append("accountId", context.appeal.accountId.toString());
          formData.append("pcnCode", context.pcnValidationResponse.pcnCode);
          formData.append("vrm", context.pcnValidationResponse.vrmCode);
          formData.append("appealName", context.appeal.name);
          formData.append("email", context.appeal.email);
          formData.append("iAmThe", context.appeal.iAmThe);
          formData.append("name", context.appeal.name);
          formData.append("address", context.appeal.address);
          formData.append("town", context.appeal.town);
          formData.append("county", context.appeal.county);
          formData.append("postcode", context.appeal.postcode);
          formData.append("homeTelephone", context.appeal.homeTelephone);
          formData.append("mobileTelephone", context.appeal.mobileTelephone);
          formData.append("workTelephone", context.appeal.workTelephone);
          formData.append("reason", context.appeal.reason);
          formData.append("postcode", context.appeal.postcode);

          context.appeal.evidenceFiles.forEach((element) => {
            // only the files not previously submitted will be added
            if (!context.appeal.filesSubmitted.find((fileSubmitted: any) => fileSubmitted === element.name)) {
              formData.append("appealFiles", element);
            }
          });

          formData.append("allowReplay", values.allowReplay ? "true" : "false");
          formData.append("privacyStatement", values.privacyStatement ? "true" : "false");

          if (context.appeal.processCompleted === true) {
            await apiRequest(EcpApiMethod.POST, `${api.addAppeal}`, formData, "multipart/form-data");

            resetContext();
            props.stepUpdate(AppealWizardStep.Success);
          } else {
            const result: IAppealResponse = await apiRequest(EcpApiMethod.POST, `${api.addAppeal}`, formData, "multipart/form-data");

            switch (result.response) {
              case AppealResponse.Other:
                handleError("We are unable to process your appeal at the moment. Please try again later.");
                break;
              case AppealResponse.WrongFileFormat:
                handleError(
                  "Some files didn't have the right format. Only JPG and PDF files are allowed. Please review your files and try again."
                );
                context.appeal.appealSubmitted = true;
                result.filesProcessed.forEach((element) => {
                  context.appeal.filesSubmitted.push(element);
                });
                break;
              case AppealResponse.FilesUploadedFailed:
                handleError("At least one of your files was not processed correctly. Please review your files and try again.");
                context.appeal.appealSubmitted = true;
                result.filesProcessed.forEach((element) => {
                  context.appeal.filesSubmitted.push(element);
                });
                break;
              case AppealResponse.Success:
                resetContext();
                props.stepUpdate(AppealWizardStep.Success);
                break;
              default:
                handleError("We are unable to to process your appeal at the moment. Please try again later.");
                break;
            }
          }
        } catch (error) {
          handleError("We are unable to to process your appeal at the moment. Please try again later.");
        }
      })();
    },
  });

  const handleError = (errorMessage: string) => {
    setError(true);
    setErrorMessage(errorMessage);
  };

  return (
    <div className="pcn-form">
      <Form action="" method="" onSubmit={handleSubmit}>
        <h2>Please review the following information before submitting your appeal.</h2>
        <FormRowView label="PCN number" value={context.pcnValidationResponse.pcnCode} />
        <FormRowView label="Vehicle registration" value={context.pcnValidationResponse.vrmCode} />
        <FormRowView label="Email" value={context.appeal.email} />
        <FormRowView label="I am the" value={context.appeal.iAmThe} />
        <FormRowView label="Name" value={context.appeal.name} />
        <FormRowView label="Address" value={context.appeal.address} />
        <FormRowView label="Town" value={context.appeal.town} />
        <FormRowView label="County" value={context.appeal.county} />
        <FormRowView label="Postcode" value={context.appeal.postcode} />
        <FormRowView label="Home telephone" value={context.appeal.homeTelephone} />
        <FormRowView label="Mobile telephone" value={context.appeal.mobileTelephone} />
        <FormRowView label="Work telephone" value={context.appeal.workTelephone} />
        {context.appeal.evidenceFiles !== undefined && context.appeal.evidenceFiles !== null && context.appeal.evidenceFiles.length > 0 && 
        <FormGroup row>
          <Col xs="12" md="3">
            <span className="pcn-label">Files: </span>
          </Col>
          <Col xs="12" md="9">
            {context.appeal.evidenceFiles !== undefined &&
              context.appeal.evidenceFiles.map((file) => (
                <div key={file.name} className="appeal-form-review-file">
                  {file.name.length > 40 ? file.name.substring(0, 40) + "..." : file.name}
                </div>
              ))}
          </Col>
        </FormGroup>
        }
        <FormGroup row>
          <Col className="mb-2 mb-md-0">
            <Input
              type="checkbox"
              name="privacyStatement"
              onChange={handleChange}
              className={!touched.privacyStatement ? "" : errors.privacyStatement ? "is-invalid" : "is-valid"}
            />
            <Label for="reply" className="ms-2">
              I have read and accept the{" "}
              <a href="/privacy-statement" target="_blank" rel="noreferrer" className="privacy-statement-link">
                Privacy Statement
              </a>
            </Label>
            <FormFeedback>{errors.privacyStatement ? errors.privacyStatement : null}</FormFeedback>
          </Col>
        </FormGroup>
        {error && <AlertMessage color="danger" message={errorMessage}></AlertMessage>}
        <FormButtons isProcessing={isProcessing} displaySubmit={true} previousStep={() => props.stepUpdate(AppealWizardStep.Evidence)} />
      </Form>
      {isProcessing && <div className="pcn-spinner"></div>}
    </div>
  );
};

export default AppealReview;
