import { useContext, useEffect, useRef, useState } from "react";
import { Col, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { AppContext } from "../../contexts/app-context";
import { useFormik } from "formik";
import { EcpApiMethod } from "../../enums/ApiMethod";
import { PayPcnWizardStep } from "../../enums/PayPcnWizardStep";
import { PaymentStatus } from "../../enums/PaymentStatus";
import { IPcnPaymentResponse } from "../../models/IPcnPaymentResponse";
import { ThreeDSErrorMessages } from "../../enums/ThreeDSErrorMessages";
import { PaymentMethod } from "../../enums/PaymentMethod";
import useApiRequest from "../../hooks/useApiRequest";
import FormRowView from "../../components/FormRowView";
import FormButtons from "../../components/FormButtons";
// import Countries from "../../data/Countries";
import * as Yup from "yup";
import * as api from "../../api/api-urls";

const PayOnline = (props: { stepUpdate: (value: PayPcnWizardStep) => void }) => {
  const context = useContext(AppContext);

  const paymentDetails = useRef({
    debtId: 0,
    pcn: "",
    vrm: "",
    siteId: "",
    amount: "",
    firstName: "",
    lastName: "",
    email: "",
    address1: "",
    city: "",
    zip: "",
    token: "",
    ccnumber: "",
    ccexp: "",
    cvv: "",
    country: "",
  });

  const ccnumberRef = useRef(null);

  const { apiRequest, isProcessing, setIsProcessing, error, setError, errorMessage, setErrorMessage } = useApiRequest();

  const [isCcnumberError, setIsCcnumberError] = useState(false);
  const [ccnumberErrorMessage, setCcnumberErrorMessage] = useState("");
  const [isCcexpError, setIsCcexpError] = useState(false);
  const [ccexpErrorMessage, setCcexpErrorMessage] = useState("");
  const [isCvvError, setIsCvvError] = useState(false);
  const [cvvErrorMessage, setCvvErrorMessage] = useState("");

  const [payButtonClicked, setPayButtonClicked] = useState(false);
  const [touchedCcnumber, setTouchedCcnumber] = useState(false);
  const [touchedCcexp, setTouchedCcexp] = useState(false);
  const [touchedCvv, setTouchedCvv] = useState(false);
  const [hide3DSForm, setHide3DSForm] = useState(true);
  const [submitClicked, setSubmitClicked] = useState(false);

  const defaultErrorMessage = "There was an error when making the payment. Please try again.";

  const validationSchema = Yup.object({
    firstName: Yup.string().nullable().required("Cardholder first name is required"),
    lastName: Yup.string().nullable().required("Cardholder last name is required"),
    email: Yup.string().nullable().email("Please enter a valid email address").notRequired(),
    address1: Yup.string().nullable().required("Cardholder address is required for payment validation"),
    city: Yup.string().nullable().required("Cardholder city is required"),
    zip: Yup.string().nullable().required("Cardholder postcode is required for payment validation"),
    // country: Yup.string().nullable().required("Country is required"),
  });

  useEffect(() => {
    setIsProcessing(true);
    setSubmitClicked(false);

    const gateway = window.Gateway.create("checkout_public_DWAP93kZF85g673B7K73h28du3fcR433");

    const threeDS = gateway.get3DSecure();
    let threeDSData: any = undefined;

    let threeDSecureInterface: any = undefined;

    window.CollectJS.configure({
      variant: "inline",
      customCss: {
        height: "42px",
        padding: "0.375rem 0.75rem",
        // width: "50%",
        // "background-color": "red !important",
        border: "1px solid #ced4da",
        // margin: 100,
        borderRadius: "0.25rem",
        alignContent: "center",
        justifyContent: "center",
        boxSizing: `border-box !important`,
      },
      focusCss: {
        color: "#212529",
        outline: 0,
      },
      invalidCss: {
        // "background-color": "pink",
      },
      fields: {
        ccnumber: {
          selector: "#ccnumber",
          title: "Enter card number",
          placeholder: "0000 0000 0000 0000",
        },
        ccexp: {
          selector: "#ccexp",
          title: "Enter card expiry date",
          placeholder: "00 / 00",
        },
        cvv: {
          selector: "#cvv",
          title: "Enter CVV",
          placeholder: "***",
        },
        googlePay: {
          selector: "#google-pay-button",
          shippingAddressRequired: false,
          billingAddressRequired: true,
          billingAddressParameters: {
            phoneNumberRequired: true,
            format: "FULL",
          },
          emailRequired: true,
          buttonType: "checkout",
          buttonLocale: "en",
        },
        applePay: {
          selector: "#apple-pay-button",
          requiredBillingContactFields: ["postalAddress", "name"],
          contactFields: ["phone", "email"],
          totalType: "final",
          type: "check-out",
          style: {
            height: "42px",
            "border-radius": "0px",
          },
        },
      },
      currency: "GBP",
      validationCallback: (field: string, status: any, message: any) => {
        switch (field) {
          case "ccnumber":
            setTouchedCcnumber(true);
            if (status) {
              setIsCcnumberError(false);
              setCcnumberErrorMessage("");
            } else {
              if (message === "Field is empty") {
                setIsCcnumberError(true);
                setCcnumberErrorMessage("Please enter the your credit card number");
              } else {
                setCcnumberErrorMessage(message);
              }
              setIsCcnumberError(true);
            }
            break;
          case "ccexp":
            setTouchedCcexp(true);
            if (status) {
              setIsCcexpError(false);
              setCcexpErrorMessage("");
            } else {
              if (message === "Field is empty") {
                setIsCcexpError(true);
                setCcexpErrorMessage("Please enter the expiry date from credit card");
              } else {
                setCcexpErrorMessage(message);
              }
              setIsCcexpError(true);
            }
            break;
          case "cvv":
            setTouchedCvv(true);
            if (status) {
              setIsCvvError(false);
              setCvvErrorMessage("");
            } else {
              if (message === "Field is empty") {
                setIsCvvError(true);
                setCvvErrorMessage("Please enter the CVV code from the back of your credit card");
              } else {
                setCvvErrorMessage(message);
              }
              setIsCvvError(true);
            }
            break;
          default:
            break;
        }
      },
      timeoutDuration: 10000,
      timeoutCallback: () => {
        setIsProcessing(false);
      },
      fieldsAvailableCallback: () => {
        setIsProcessing(false);
      },

      callback: (collectJsResponse: any) => {
        const handleResult = (result: any) => {
          context.pcnPaymentResponse.paymentMethod = collectJsResponse.tokenType;

          switch (result.status) {
            case PaymentStatus.PaymentAuthorized:
              props.stepUpdate(PayPcnWizardStep.PaySuccess);
              break;
            case PaymentStatus.PaymentDeclined:
              setError(true);
              if (result.responseDetail)
                setErrorMessage("The payment has been declined. " + result.responseDetail + " Please check your details and try again.");
              else setErrorMessage("The payment has been declined. Please check your details and try again.");
              break;
            default:
              setError(true);
              setErrorMessage(defaultErrorMessage);
              break;
          }
        };

        const handleError = () => {
          setError(true);
          setErrorMessage(defaultErrorMessage);
          setIsProcessing(false);
        };

        if (collectJsResponse.tokenType === PaymentMethod.GooglePay || collectJsResponse.tokenType === PaymentMethod.ApplePay) {
          (async () => {
            setIsProcessing(true);
            try {
              const item = {
                debtId: context.pcnValidationResponse.debtId,
                pcn: context.pcnValidationResponse.pcnCode,
                vrm: context.pcnValidationResponse.vrmCode,
                siteId: context.pcnValidationResponse.siteId,
                amount: context.pcnValidationResponse.chargeAmt.toString(),
                firstName: collectJsResponse.wallet.billingInfo.firstName,
                lastName: collectJsResponse.wallet.billingInfo.lastName,
                email: collectJsResponse.wallet.email,
                address1: collectJsResponse.wallet.billingInfo.address1,
                city: collectJsResponse.wallet.billingInfo.city,
                postalCode: collectJsResponse.wallet.billingInfo.postalCode,
                country: collectJsResponse.wallet.billingInfo.country,
                token: collectJsResponse.token,
              };

              const result: IPcnPaymentResponse = await apiRequest(EcpApiMethod.POST, `${api.onlinePayment}`, item);

              context.pcnPaymentResponse = result;

              setSubmitClicked(false);
              handleResult(result);
            } catch (error) {
              handleError();
            }
          })();
        } else {
          threeDSData = {
            paymentToken: collectJsResponse.token,
            currency: "GBP",
            amount: context.pcnValidationResponse.chargeAmt.toString(),
            email: "noreply@eurocarparks.co.uk",
            phone: "",
            city: paymentDetails.current.city,
            state: "",
            address1: paymentDetails.current.address1,
            country: paymentDetails.current.country,
            firstName: paymentDetails.current.firstName,
            lastName: paymentDetails.current.lastName,
            postalCode: paymentDetails.current.zip,
          };

          threeDSecureInterface = threeDS.createUI(threeDSData);

          if (!threeDSecureInterface.isMounted()) {
            threeDSecureInterface.start("#threeDSMountPoint");
          }

          threeDSecureInterface.on("challenge", (e: any) => {
            setIsProcessing(false);
            setHide3DSForm(false);
            setSubmitClicked(true);
          });

          threeDSecureInterface.on("complete", (e: any) => {
            (async () => {
              setIsProcessing(true);
              setHide3DSForm(true);
              try {
                const item = {
                  debtId: paymentDetails.current.debtId,
                  pcn: paymentDetails.current.pcn,
                  vrm: paymentDetails.current.vrm,
                  siteId: paymentDetails.current.siteId,
                  amount: paymentDetails.current.amount,
                  firstName: threeDSData.firstName,
                  lastName: threeDSData.lastName,
                  email: paymentDetails.current.email,
                  address1: threeDSData.address1,
                  city: threeDSData.city,
                  zip: threeDSData.zip,
                  country: threeDSData.country,
                  token: collectJsResponse.token,
                  cavv: e.cavv,
                  xid: e.xid,
                  eci: e.eci,
                  cardHolderAuth: e.cardHolderAuth,
                  threeDsVersion: e.threeDsVersion,
                  directoryServerId: e.directoryServerId,
                };

                const result: IPcnPaymentResponse = await apiRequest(EcpApiMethod.POST, `${api.onlinePayment}`, item);

                context.pcnPaymentResponse = result;

                // component to be unmounted no matter what result is from the API
                unmount3DS();
                setSubmitClicked(false);

                handleResult(result);
              } catch (error) {
                handleError();
              }
            })();
          });

          const unmount3DS = () => {
            threeDSecureInterface.unmount();
          };

          threeDSecureInterface.on("failure", (e: any) => {
            generateThreeDSErrorMessage(e.code, e.message);
          });

          threeDSecureInterface.on("error", (e: any) => {
            generateThreeDSErrorMessage(e.code, e.message);
          });

          const resetComponent = (message: string) => {
            setHide3DSForm(true);
            setErrorMessage(message);
            setIsProcessing(false);
            setError(true);
            unmount3DS();
            setSubmitClicked(false);
          };

          const generateThreeDSErrorMessage = (code: string, message: string) => {
            if (code === "TRANSACTION_STATUS_N") {
              resetComponent(ThreeDSErrorMessages.NoAuthenticated);
            } else if (code === "TRANSACTION_STATUS_U") {
              resetComponent(ThreeDSErrorMessages.VerificationNotPerformed);
            } else if (code === "TRANSACTION_STATUS_R") {
              resetComponent(ThreeDSErrorMessages.IssuerRejected);
            } else if (code === undefined && message === "Invalid Formatted Message Invalid Formatted Message") {
              resetComponent(ThreeDSErrorMessages.InvalidFormattedMessage);
            } else if (code === undefined && message === "Transaction Timed Out") {
              resetComponent(ThreeDSErrorMessages.TransactionTimedOut);
            } else if (code === undefined && message === "A 3DS error occurred: Error Processing PARes") {
              resetComponent(ThreeDSErrorMessages.Pares);
            } else {
              resetComponent(ThreeDSErrorMessages.Default);
            }
          };
        }
      },
    });
    return () => {
      if (threeDSecureInterface !== undefined) threeDSecureInterface.unmount();
    };
  }, []);

  const { handleSubmit, handleChange, touched, values, errors } = useFormik({
    initialValues: {
      debtId: context.pcnValidationResponse.debtId,
      pcn: context.pcnValidationResponse.pcnCode,
      vrm: context.pcnValidationResponse.vrmCode,
      siteId: context.pcnValidationResponse.siteId,
      amount: context.pcnValidationResponse.chargeAmt,
      firstName: "",
      lastName: "",
      email: "",
      address1: "",
      city: "",
      zip: "",
      country: "GB",
      token: "",
      ccnumber: "",
      ccexp: "",
      cvv: "",
    },
    validationSchema,
    onSubmit(values) {
      try {
        if (!submitClicked) {
          setError(false);
          setIsProcessing(true);
          paymentDetails.current = {
            debtId: values.debtId,
            pcn: values.pcn,
            vrm: values.vrm,
            siteId: values.siteId,
            amount: values.amount.toString(),
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            address1: values.address1,
            city: values.city,
            zip: values.zip,
            country: "GB", //values.country,
            token: "",
            ccnumber: "",
            ccexp: "",
            cvv: "",
          };

          window.CollectJS.startPaymentRequest();
        }
      } catch (error) {
        setError(true);
        setErrorMessage("There was an error when making the payment");
        setIsProcessing(false);
      }
    },
  });

  const handleFormSubmit = (e: any) => {
    setPayButtonClicked(true);
    handleSubmit(e);
  };

  return (
    <div>
      <Form action="" method="" className="pcn-form" onSubmit={handleFormSubmit} autoComplete="false">
        <div className="pcn-info">
          <h2>Online payment</h2>
          <p>
            Please enter your payment information in the form below to make your payment now. If you enter an email address, we will send
            you a receipt for your payment.{" "}
            <b>
              The information you enter is solely used for payment verification and processing is not stored and will not be used for any
              other purpose.
            </b>
          </p>
        </div>

        <div className="mt-1 mb-4">
          <div id="apple-pay-button"></div>
          <div id="google-pay-button"></div>
        </div>

        <FormRowView label="PCN number" value={values.pcn} />
        <FormGroup row hidden={!context?.pcnValidationResponse.enableMerchantEmailReceipt}>
          <Col xs="12" md="3">
            <Label htmlFor="email" className="pcn-label">
              Email address
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="email"
              className={!touched.email ? "" : errors.email ? "is-invalid" : "is-valid"}
              placeholder="Enter email address for receipt (optional)"
              value={values.email}
              onChange={handleChange}
            />
            <FormFeedback>{errors.email ? errors.email : null}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="firstName" className="pcn-label">
              First name
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="firstName"
              className={!touched.firstName ? "" : errors.firstName ? "is-invalid" : "is-valid"}
              placeholder="Enter cardholders first name"
              value={values.firstName}
              onChange={handleChange}
            />
            <FormFeedback>{errors.firstName ? errors.firstName : null}</FormFeedback>{" "}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="lastName" className="pcn-label">
              Last name
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="lastName"
              className={!touched.lastName ? "" : errors.lastName ? "is-invalid" : "is-valid"}
              placeholder="Enter cardholders last name"
              value={values.lastName}
              onChange={handleChange}
            />
            <FormFeedback>{errors.lastName ? errors.lastName : null}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="address1" className="pcn-label">
              Address line 1
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="address1"
              className={!touched.address1 ? "" : errors.address1 ? "is-invalid" : "is-valid"}
              placeholder="Enter first line of cardholder's address"
              value={values.address1}
              onChange={handleChange}
            />
            <FormFeedback>{errors.address1 ? errors.address1 : null}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="city" className="pcn-label">
              City
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="city"
              className={!touched.city ? "" : errors.city ? "is-invalid" : "is-valid"}
              placeholder="Enter cardholder's city"
              value={values.city}
              onChange={handleChange}
            />
            <FormFeedback>{errors.city ? errors.city : null}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="zip" className="pcn-label">
              Postcode
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="zip"
              className={!touched.zip ? "" : errors.zip ? "is-invalid" : "is-valid"}
              placeholder="Enter cardholder's postcode"
              value={values.zip}
              onChange={handleChange}
            />
            <FormFeedback>{errors.zip ? errors.zip : null}</FormFeedback>
          </Col>
        </FormGroup>
        {/* <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="country" className="pcn-label">
              Country
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="select"
              name="country"
              value={values.country}
              className={!touched.country ? "" : errors.country ? "is-invalid" : "is-valid"}
              onChange={handleChange}
            >
              <option key="" value="">
                Select a country
              </option>
              {Countries.data.map(({ id, value }) => (
                <option key={id} value={id}>
                  {value}
                </option>
              ))}
            </Input>
            <FormFeedback>{errors.country ? errors.country : null}</FormFeedback>
          </Col>
        </FormGroup> */}
        <FormRowView label="Payment amount" value={"??" + values.amount.toFixed(2)} />

        <FormGroup row>
          <Col xs="12" md="3"></Col>
          <Col xs="12" md="9">
            <div id="threeDSMountPoint" hidden={hide3DSForm}></div>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col xs="12" md="3">
            <span className="pcn-label">Card number</span>
          </Col>
          <Col xs="12" md="9">
            <div
              id="ccnumber"
              ref={ccnumberRef}
              className={"payment-field ccnumber " + (!touched.ccnumber ? "" : errors.ccnumber ? "is-invalid" : "is-valid")}
            ></div>
            {!touchedCcnumber ? (
              <FormFeedback className="d-block">{payButtonClicked && "Please enter the your credit card number"}</FormFeedback>
            ) : (
              <FormFeedback className="d-block">{payButtonClicked && isCcnumberError && ccnumberErrorMessage}</FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="3">
            <span className="pcn-label">Expiry date</span>
          </Col>
          <Col xs="12" md="9">
            <div id="ccexp" className={"payment-field ccexp " + (!touched.ccexp ? "" : errors.ccexp ? "is-invalid" : "is-valid")}></div>
            {!touchedCcexp ? (
              <FormFeedback className="d-block">{payButtonClicked && "Please enter the expiry date from credit card"}</FormFeedback>
            ) : (
              <FormFeedback className="d-block">{payButtonClicked && isCcexpError && ccexpErrorMessage}</FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="3">
            <span className="pcn-label">CVV</span>
          </Col>
          <Col xs="12" md="9">
            <div id="cvv" className={"payment-field cvv " + (!touched.cvv ? "" : errors.cvv ? "is-invalid" : "is-valid")}></div>
            {!touchedCvv ? (
              <FormFeedback className="d-block">
                {payButtonClicked && "Please enter the CVV code from the back of your credit card"}
              </FormFeedback>
            ) : (
              <FormFeedback className="d-block">{payButtonClicked && isCvvError && cvvErrorMessage}</FormFeedback>
            )}
          </Col>
        </FormGroup>

        {error ? <div className="pcn-form-error">{errorMessage}</div> : null}

        <FormButtons
          isProcessing={false}
          displaySubmit={true}
          nextText="Pay Now"
          previousStep={() => props.stepUpdate(PayPcnWizardStep.Ok)}
        ></FormButtons>
      </Form>
      {isProcessing && <div className="pcn-spinner"></div>}
    </div>
  );
};

export default PayOnline;
