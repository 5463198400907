import { useContext } from "react";
import { AppContext, clearPcnValidationResponseContext } from "../../contexts/app-context";
import { PayPcnWizardStep } from "../../enums/PayPcnWizardStep";
import FormRowView from "../../components/FormRowView";
import FormButtons from "../../components/FormButtons";

const PayParkingNotProcessed = (props: { stepUpdate: (value: PayPcnWizardStep) => void }) => {
  const context = useContext(AppContext);

  return (
    <div className="pcn-form row">
      <div className="pcn-info">
        <h2>PCN not processed</h2>
      </div>
      <FormRowView label="PCN number" value={context.pcnValidationResponse.pcnCode} />
      <FormRowView label="Vehicle registration" value={context.pcnValidationResponse.vrmCode} />
      <FormRowView label="Date" value={context.pcnValidationResponse.pcnDate} />
      <FormRowView label="Time" value={context.pcnValidationResponse.pcnTime} />
      <div className="pcn-info">
        <p>Please try again later. This charge notice is being processed and will be available for payment shortly.</p>
      </div>
      <FormButtons
        isProcessing={false}
        previousStep={() => props.stepUpdate(PayPcnWizardStep.Verification)}
        nextStep={() => {
          clearPcnValidationResponseContext();
          props.stepUpdate(PayPcnWizardStep.Verification);
        }}
        nextText="Start Again"
      ></FormButtons>
    </div>
  );
};

export default PayParkingNotProcessed;
