import { Fragment, useContext, useEffect } from "react";
import { Col, FormGroup, Input } from "reactstrap";
import { AppContext } from "../../contexts/app-context";

const EvidenceFiles = (props: { refreshFilesDetails: boolean; files: any[]; removeFile: (index: number) => void }) => {
  const context = useContext(AppContext);

  useEffect(() => {}, [props.refreshFilesDetails]);

  const fileIsSubmitted = (fileName: string) => {
    const found = context.appeal.filesSubmitted.find((element: string) => element === fileName);

    return found;
  };

  return (
    <div>
      {props.files.map(
        (file, index) =>
          file !== undefined && (
            <FormGroup row key={file.name} className="mt-4">
              <Col xs="12" md="9">
                <div style={{ height: "38px" }}>{file.name.length > 35 ? file.name.substring(0, 35) + "..." : file.name}</div>
              </Col>
              <Col xs="12" md="3">
                {fileIsSubmitted(file.name) ? (
                  <div>Uploaded</div>
                ) : (
                  <Fragment>
                    <Input
                      type="button"
                      value="Remove"
                      className="d-none d-sm-block d-md-block d-sm-none float-end"
                      onClick={() => {
                        props.removeFile(index);
                      }}
                      style={{ width: "initial" }}
                    />
                    <Input
                      type="button"
                      value="Remove"
                      className="d-md-none d-md-none"
                      onClick={() => {
                        props.removeFile(index);
                      }}
                      style={{ width: "initial" }}
                    />
                  </Fragment>
                )}
              </Col>
            </FormGroup>
          )
      )}
    </div>
  );
};

export default EvidenceFiles;
