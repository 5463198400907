import { useState } from "react";
import { AppealWizardStep } from "../../enums/AppealWizardStep";
import Verification from "./Verification";
import Closed from "./AppealClosed";
import Exists from "./AppealExists";
import NotAllowed from "./AppealNotAllowed";
import Popla from "./AppealPopla";
import ThirdParty from "./AppealThirdParty";
import ContactInformation from "./AppealContactInformation";
import PersonalDetails from "./AppealPersonalDetails";
import Reason from "./AppealReason";
import Evidence from "./AppealEvidence";
import Review from "./AppealReview";
import Success from "./AppealSuccess";

const AppealWizard = () => {
  const [step, setStep] = useState(AppealWizardStep.Verification);

  const handleStepUpdate = (value: AppealWizardStep) => {
    setStep(value);
  };

  return (
    <div>
      {step === AppealWizardStep.Verification && <Verification stepUpdate={handleStepUpdate} />}
      {step === AppealWizardStep.Exists && <Exists stepUpdate={handleStepUpdate} />}
      {step === AppealWizardStep.NotAllowed && <NotAllowed stepUpdate={handleStepUpdate} />}
      {step === AppealWizardStep.Cancelled && <Closed stepUpdate={handleStepUpdate} />}
      {step === AppealWizardStep.Popla && <Popla stepUpdate={handleStepUpdate} />}
      {step === AppealWizardStep.ThirdParty && <ThirdParty stepUpdate={handleStepUpdate} />}
      {step === AppealWizardStep.ContactInformation && <ContactInformation stepUpdate={handleStepUpdate} />}
      {step === AppealWizardStep.PersonalDetails && <PersonalDetails stepUpdate={handleStepUpdate} />}
      {step === AppealWizardStep.Reason && <Reason stepUpdate={handleStepUpdate} />}
      {step === AppealWizardStep.Evidence && <Evidence stepUpdate={handleStepUpdate} />}
      {step === AppealWizardStep.Review && <Review stepUpdate={handleStepUpdate} />}
      {step === AppealWizardStep.Success && <Success stepUpdate={handleStepUpdate} />}
    </div>
  );
};

export default AppealWizard;
