import { useContext, useEffect } from "react";
import { Col, Form, Row } from "reactstrap";
import { AppContext } from "../../contexts/app-context";
import { PayPcnWizardStep } from "../../enums/PayPcnWizardStep";
import { IPcnPaymentResponse } from "../../models/IPcnPaymentResponse";
import { EcpApiMethod } from "../../enums/ApiMethod";
import { PaymentStatus } from "../../enums/PaymentStatus";
import useApiRequest from "../../hooks/useApiRequest";
import FormRowView from "../../components/FormRowView";
import FormButtons from "../../components/FormButtons";
import * as api from "../../api/api-urls";

const PayParkingInfo = (props: { stepUpdate: (value: PayPcnWizardStep) => void }) => {
  const context = useContext(AppContext);

  const defaultErrorMessage = "There was an error when making the payment. Please try again.";

  const { apiRequest, isProcessing, setIsProcessing, error, setError, errorMessage, setErrorMessage } = useApiRequest();

  useEffect(() => {
    window.CollectJS.configure({
      currency: "GBP",
      country: "GB",
      price: context.pcnValidationResponse.chargeAmt.toFixed(2),
      fields: {
        googlePay: {
          selector: "#google-pay-button",
          shippingAddressRequired: false,
          billingAddressRequired: true,
          billingAddressParameters: {
            phoneNumberRequired: true,
            format: "FULL",
          },
          emailRequired: true,
          buttonType: "checkout",
          buttonLocale: "en",
        },
        applePay: {
          selector: "#apple-pay-button",
          requiredBillingContactFields: ["postalAddress", "name"],
          contactFields: ["phone", "email"],
          totalType: "final",
          type: "check-out",
          style: {
            height: "42px",
            "border-radius": "0px",
          },
        },
      },
      callback: (collectJsResponse: any) => {
        setError(false);

        (async () => {
          setIsProcessing(true);
          try {
            const item = {
              debtId: context.pcnValidationResponse.debtId,
              pcn: context.pcnValidationResponse.pcnCode,
              vrm: context.pcnValidationResponse.vrmCode,
              siteId: context.pcnValidationResponse.siteId,
              amount: context.pcnValidationResponse.chargeAmt.toFixed(2),
              firstName: collectJsResponse.wallet.billingInfo.firstName,
              lastName: collectJsResponse.wallet.billingInfo.lastName,
              email: collectJsResponse.wallet.email,
              address1: collectJsResponse.wallet.billingInfo.address1,
              city: collectJsResponse.wallet.billingInfo.city,
              zip: collectJsResponse.wallet.billingInfo.postalCode,
              country: collectJsResponse.wallet.billingInfo.country,
              token: collectJsResponse.token,
            };

            const result: IPcnPaymentResponse = await apiRequest(EcpApiMethod.POST, `${api.onlinePayment}`, item);

            context.pcnPaymentResponse = result;

            context.pcnPaymentResponse.paymentMethod = collectJsResponse.tokenType;

            switch (result.status) {
              case PaymentStatus.PaymentAuthorized:
                props.stepUpdate(PayPcnWizardStep.PaySuccess);
                break;
              case PaymentStatus.PaymentDeclined:
                setError(true);
                if (result.responseDetail)
                  setErrorMessage("The payment has been declined. " + result.responseDetail + " Please check your details and try again.");
                else setErrorMessage("The payment has been declined. Please check your details and try again.");
                break;
              default:
                setError(true);
                setErrorMessage(defaultErrorMessage);
                break;
            }
          } catch (error) {
            setError(true);
            setErrorMessage(defaultErrorMessage);
            setIsProcessing(false);
          }
        })();
      },
    });
  }, []);

  //
  // Do not remove. It's used for testing as Apple pay button doesn't render on localhost.
  //
  // const applePayFakeResult = (status: PaymentStatus) => {
  //   const responseDetail = "Gateway disabled for testing";

  //   switch (status) {
  //     case PaymentStatus.PaymentAuthorized:
  //       props.stepUpdate(PayPcnWizardStep.PaySuccess);
  //       break;
  //     case PaymentStatus.PaymentDeclined:
  //       setError(true);
  //       if (responseDetail)
  //         setErrorMessage("The payment has been declined. " + responseDetail + " Please check your details and try again.");
  //       else setErrorMessage("The payment has been declined. Please check your details and try again.");
  //       break;
  //     default:
  //       setError(true);
  //       setErrorMessage("There was an error when making the payment. Please try again.");
  //       break;
  //   }
  // };

  return (
    <div>
      <Form action="" method="" className="pcn-form" autoComplete="false">
        <div className="pcn-info">
          <h2>Important Note</h2>
          <p>
            Please check that the details below are correct, and then choose how you wish to pay. Payment by cheque incurs an additional
            ??2.50 handling charge.
          </p>
        </div>
        <FormRowView label="PCN number" value={context.pcnValidationResponse.pcnCode} />
        <FormRowView label="Vehicle registration" value={context.pcnValidationResponse.vrmCode} />
        <FormRowView label="Date" value={context.pcnValidationResponse.pcnDate} />
        <FormRowView label="Time" value={context.pcnValidationResponse.pcnTime} />
        <FormRowView label="Parking charge" value={"??" + context.pcnValidationResponse.chargeAmt.toFixed(2)} />

        {error ? <div className="pcn-form-error mb-2">{errorMessage}</div> : null}

        <div>
          <Row>
            <Col>
              <div className="mt-3">
                <Row>
                  <Col sm="0" md="4"></Col>
                  <Col sm="0" md="4"></Col>
                  <Col sm="12" md="4">
                    <div id="apple-pay-button"></div>
                    <div id="google-pay-button"></div>

                    {/* Do not remove. It's used for testing as Apple pay button doesn't render on localhost. */}
                    {/* <input
                        type="button"
                        value={"Check out with Pay"}
                        // className="pcn-btn pcn-btn-primary float-end"
                        style={{ height: "42px", borderRadius: "0px", backgroundColor: "black", color: "white" }}
                        onClick={() => applePayFakeResult(PaymentStatus.PaymentDeclined)}
                      ></input> */}
                  </Col>
                </Row>
              </div>

              {/* All sizes but mobile devices. It will be hidden for <768px */}
              <div className="d-none d-md-block" style={{ marginTop: "-10px" }}>
                <FormButtons
                  isProcessing={false}
                  previousStep={() => props.stepUpdate(PayPcnWizardStep.Verification)}
                  nextStep={() => props.stepUpdate(PayPcnWizardStep.PayOnline)}
                  nextText={"Pay Online - ??" + context.pcnValidationResponse.chargeAmt.toFixed(2)}
                ></FormButtons>
                <FormButtons
                  isProcessing={false}
                  hidePrevious={true}
                  nextStep={() => props.stepUpdate(PayPcnWizardStep.PayCheque)}
                  nextText={"Pay by Cheque - ??" + (context.pcnValidationResponse.chargeAmt + 2.5).toFixed(2)}
                ></FormButtons>
              </div>

              {/* Mobile devices. It will be hidden for >=768px */}
              <div className="d-block d-md-none">
                <FormButtons
                  isProcessing={false}
                  nextStep={() => props.stepUpdate(PayPcnWizardStep.PayOnline)}
                  nextText={"Pay Online - ??" + context.pcnValidationResponse.chargeAmt.toFixed(2)}
                  hidePrevious={true}
                ></FormButtons>
                <FormButtons
                  isProcessing={false}
                  hidePrevious={true}
                  nextStep={() => props.stepUpdate(PayPcnWizardStep.PayCheque)}
                  nextText={"Pay by Cheque - ??" + (context.pcnValidationResponse.chargeAmt + 2.5).toFixed(2)}
                ></FormButtons>
                <FormButtons
                  isProcessing={false}
                  previousStep={() => props.stepUpdate(PayPcnWizardStep.Verification)}
                  hideNext={true}
                ></FormButtons>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
      {isProcessing && <div className="pcn-spinner"></div>}
    </div>
  );
};

export default PayParkingInfo;
