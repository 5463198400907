import { Form } from "reactstrap";
import { AppealWizardStep } from "../../enums/AppealWizardStep";
import { clearPcnValidationResponseContext } from "../../contexts/app-context";
import FormButtons from "../../components/FormButtons";

const AppealSuccess = (props: { stepUpdate: (value: AppealWizardStep) => void }) => {
  return (
    <div>
      <Form action="" method="" className="pcn-form row">
        <div className="pcn-info row">
          <h2>Appeal received</h2>
          <p>Your appeal has been received and may take up to 28 days to process.</p>
        </div>
        <FormButtons
          isProcessing={false}
          hidePrevious={true}
          nextText="Start Again"
          nextStep={() => {
            clearPcnValidationResponseContext();
            props.stepUpdate(AppealWizardStep.Verification);
          }}
        ></FormButtons>
      </Form>
    </div>
  );
};

export default AppealSuccess;
