import { AxiosRequestConfig } from "axios";
import { useState, useCallback } from "react";
import EcpApi from "../api/api";

const useApiRequest = () => {
  const [response, setResponse] = useState<any>();
  const [error, setError] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const apiRequest = useCallback(async (method: AxiosRequestConfig["method"], url: string, values?: any, type?: any) => {
    setError(false);
    try {
      setIsProcessing(true);
      const result = await EcpApi(method, url, values, type);
      setResponse(result);
      setIsProcessing(false);
      return result;
    } catch (err) {
      setError(true);
      setIsProcessing(false);
      throw err;
    }
  }, []);

  return { apiRequest, response, error, setError, isProcessing, setIsProcessing, errorMessage, setErrorMessage };
};

export default useApiRequest;
