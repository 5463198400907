export enum ThreeDSErrorMessages {
  // Default = "Gateway error - There was an error when making the payment. Please try again.",
  // NoAuthenticated = "Gateway error - Not Authenticated/Account Not Verified; Transaction denied.",
  // VerificationNotPerformed = "Gateway error - Authentication/Account Verification Could Not Be Performed; Technical or other problem.",
  // IssuerRejected = "Gateway error - Authentication/ Account Verification Rejected; Issuer is rejecting authentication/verification.",
  // InvalidFormattedMessage = "Gateway error - Invalid Formatted Message Invalid Formatted Message.",
  // TransactionTimedOut = "Gateway error - Transaction Timed Out.",
  // Pares = "Gateway error - A 3DS error occurred: Error Processing PARes.",
  Default = "We have been unable to take your payment, please try again later.",
  NoAuthenticated = "We have been unable to take your payment, please try again later.",
  VerificationNotPerformed = "We have been unable to take your payment, please try again later.",
  IssuerRejected = "We have been unable to take your payment, please try again later.",
  InvalidFormattedMessage = "We have been unable to take your payment, please try again later.",
  TransactionTimedOut = "We have been unable to take your payment, please try again later.",
  Pares = "We have been unable to take your payment, please try again later.",
}
