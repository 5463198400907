import React from "react";
import { Alert, Row, Col } from "reactstrap";

//
// It renders a message.
//

const AlertMessage = (props: { color: string; message: string }) => {
  return (
    <Row className="mt-3">
      <Col className="col-12">
        <Alert color={props.color} className="fw-bold">
          {props.message}
        </Alert>
      </Col>
    </Row>
  );
};

export default AlertMessage;
