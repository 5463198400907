export enum AppealWizardStep {
  Verification,
  Exists,
  NotAllowed,
  Cancelled,
  Popla,
  ThirdParty,
  ContactInformation,
  PersonalDetails,
  Reason,
  Evidence,
  Review,
  Success,
}
