import { useContext } from "react";
import { Form, FormGroup, Col, Label, Input, FormFeedback } from "reactstrap";
import { AppContext } from "../../contexts/app-context";
import { AppealWizardStep } from "../../enums/AppealWizardStep";
import { useFormik } from "formik";
import FormRowView from "../../components/FormRowView";
import FormButtons from "../../components/FormButtons";
import * as Yup from "yup";

const AppealPersonalDetails = (props: { stepUpdate: (value: AppealWizardStep) => void }) => {
  const context = useContext(AppContext);

  const iAmTheData = [
    { id: 1, value: "Registered keeper" },
    { id: 2, value: "Driver and Registered keeper" },
    { id: 3, value: "Hirer / Lease" },
    { id: 4, value: "None of the above" },
  ];

  const regExTelephone = /^(\+\d+|\d+|\(\d+\)|\s)*$/;
  
  const validationSchema = Yup.object({
    iAmTheId: Yup.number()
      .required("Please specify your involvement with this PCN")
      .min(1, "Please specify your involvement with this PCN"),
    name: Yup.string().required("Please provide your full name"),
    address: Yup.string()
      .required("Please provide your address")
      .matches(/^(?!07|01|02|\+|^\d{7,}).*$/, "Please enter a valid address which does not start with '+', '07', '01', '02' and does not have more than 6 consecutive numbers."),
    town: Yup.string().required("Please provide your town"),
    postcode: Yup.string().required("Please provide your postcode"),
    homeTelephone: Yup.string()
      .matches(regExTelephone, "Please enter a valid telephone number using only '+', spaces, '(', ')' and numeric digits."),
    mobileTelephone: Yup.string()
      .matches(regExTelephone, "Please enter a valid telephone number using only '+', spaces, '(', ')' and numeric digits."),
      workTelephone: Yup.string()
      .matches(regExTelephone, "Please enter a valid telephone number using only '+', spaces, '(', ')' and numeric digits."),
  });

  const { handleSubmit, handleChange, touched, values, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      iAmTheId: context.appeal.iAmTheId,
      name: context.appeal.name,
      address: context.appeal.address,
      town: context.appeal.town,
      county: context.appeal.county,
      postcode: context.appeal.postcode,
      homeTelephone: context.appeal.homeTelephone,
      mobileTelephone: context.appeal.mobileTelephone,
      workTelephone: context.appeal.workTelephone,
    },
    validationSchema,
    onSubmit(values) {
      // eslint-disable-next-line eqeqeq
      const selected = iAmTheData.find((element) => element.id == values.iAmTheId);

      context.appeal.iAmTheDriver = selected?.id === 2 ? true : false;
      context.appeal.iAmTheId = values.iAmTheId;
      context.appeal.iAmThe = selected !== undefined ? selected.value : "";
      context.appeal.name = values.name;
      context.appeal.address = values.address;
      context.appeal.town = values.town;
      context.appeal.county = values.county;
      context.appeal.postcode = values.postcode;
      context.appeal.homeTelephone = values.homeTelephone;
      context.appeal.mobileTelephone = values.mobileTelephone;
      context.appeal.workTelephone = values.workTelephone;

      props.stepUpdate(AppealWizardStep.Reason);
    },
  });

  return (
    <div>
      <Form action="" method="" className="pcn-form" onSubmit={handleSubmit}>
        <div className="pcn-info">
          <h2>Personal details</h2>
          <p>
            Please enter details about yourself and the appeal.{" "}
            <b>
              The information you enter is solely used for registering and contacting you about your appeal and will not be used for any
              other purpose.
            </b>
          </p>
        </div>
        <FormRowView label="PCN number" value={context.pcnValidationResponse.pcnCode} />
        <FormRowView label="Vehicle registration" value={context.pcnValidationResponse.vrmCode} />
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="iAmThe" className="pcn-label">
              I am the
            </Label>
          </Col>
          <Col xs="12" md="9" className="mb-2 mb-md-0">
            <Input
              type="select"
              name="iAmTheId"
              onChange={handleChange}
              value={values.iAmTheId}
              disabled={context.appeal.appealSubmitted}
              className={!touched.iAmTheId ? "" : errors.iAmTheId ? "is-invalid" : "is-valid"}
            >
              <option key={0} value={0}>
                Select
              </option>
              {iAmTheData.map(({ id, value }) => (
                <option key={id} value={id}>
                  {value}
                </option>
              ))}
            </Input>
            <FormFeedback>{errors.iAmTheId ? errors.iAmTheId : null}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="name" className="pcn-label">
              Name
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="name"
              placeholder="Your full name"
              onChange={handleChange}
              value={values.name}
              disabled={context.appeal.appealSubmitted}
              className={!touched.name ? "" : errors.name ? "is-invalid" : "is-valid"}
            />
            <FormFeedback>{errors.name ? errors.name : null}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="address" className="pcn-label">
              Address
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="address"
              placeholder="Your postal address"
              onChange={handleChange}
              value={values.address}
              disabled={context.appeal.appealSubmitted}
              className={!touched.address ? "" : errors.address ? "is-invalid" : "is-valid"}
            />
            <FormFeedback>{errors.address ? errors.address : null}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="town" className="pcn-label">
              Town
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="town"
              placeholder="Your town"
              onChange={handleChange}
              value={values.town}
              disabled={context.appeal.appealSubmitted}
              className={!touched.town ? undefined : errors.town ? "is-invalid" : "is-valid"}
            />
            <FormFeedback>{errors.town ? errors.town : null}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="county" className="pcn-label">
              County
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="county"
              placeholder="Your county"
              onChange={handleChange}
              value={values.county}
              disabled={context.appeal.appealSubmitted}
              className={!touched.county ? "" : errors.county ? "is-invalid" : "is-valid"}
            />
            <FormFeedback>{errors.county ? errors.county : null}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="postcode" className="pcn-label">
              Postcode
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="postcode"
              placeholder="Your postcode"
              onChange={handleChange}
              value={values.postcode}
              disabled={context.appeal.appealSubmitted}
              className={!touched.postcode ? "" : errors.postcode ? "is-invalid" : "is-valid"}
            />
            <FormFeedback>{errors.postcode ? errors.postcode : null}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="homeTelephone" className="pcn-label">
              Home telephone
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="homeTelephone"
              placeholder="Your home telephone (optional)"
              onChange={handleChange}
              value={values.homeTelephone}
              disabled={context.appeal.appealSubmitted}
              className={!touched.homeTelephone ? "" : errors.homeTelephone ? "is-invalid" : "is-valid"}
            />
            <FormFeedback>{errors.homeTelephone ? errors.homeTelephone : null}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="mobileTelephone" className="pcn-label">
              Mobile telephone
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="mobileTelephone"
              placeholder="Your mobile telephone (optional)"
              onChange={handleChange}
              value={values.mobileTelephone}
              disabled={context.appeal.appealSubmitted}
              className={!touched.mobileTelephone ? "" : errors.mobileTelephone ? "is-invalid" : "is-valid"}
            />
            <FormFeedback>{errors.mobileTelephone ? errors.mobileTelephone : null}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="workTelephone" className="pcn-label">
              Work telephone
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="workTelephone"
              placeholder="Your work telephone (optional)"
              onChange={handleChange}
              value={values.workTelephone}
              disabled={context.appeal.appealSubmitted}
              className={!touched.workTelephone ? "" : errors.workTelephone ? "is-invalid" : "is-valid"}
            />
            <FormFeedback>{errors.workTelephone ? errors.workTelephone : null}</FormFeedback>
          </Col>
        </FormGroup>
        <FormButtons isProcessing={false} previousStep={() => props.stepUpdate(AppealWizardStep.ContactInformation)} />
      </Form>
    </div>
  );
};

export default AppealPersonalDetails;
