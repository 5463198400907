import { useContext } from "react";
import { Form, FormGroup, Col, Label, Input, FormFeedback } from "reactstrap";
import { AppContext } from "../../contexts/app-context";
import { IPcnValidationResponse } from "../../models/IPcnValidationResponse";
import { PayPcnWizardStep } from "../../enums/PayPcnWizardStep";
import { useFormik } from "formik";
import { EcpApiMethod } from "../../enums/ApiMethod";
import useApiRequest from "../../hooks/useApiRequest";
import FormButtons from "../../components/FormButtons";
import AlertMessage from "../../components/AlertMessage";
import * as Yup from "yup";
import * as api from "../../api/api-urls";

const Verification = (props: { stepUpdate: (value: PayPcnWizardStep) => void }) => {
  const context = useContext(AppContext);

  const { apiRequest, isProcessing, error, setError, errorMessage, setErrorMessage } = useApiRequest();

  const validationSchema = Yup.object({
    pcn: Yup.string().required("PCN number is required"),
    vrm: Yup.string().nullable().required("Vehicle registration is required"),
  });

  const { handleSubmit, handleChange, touched, values, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      pcn: context.pcnValidationResponse.pcnCode !== undefined ? context.pcnValidationResponse.pcnCode : "",
      vrm: context.pcnValidationResponse.vrmCode,
    },
    validationSchema,
    onSubmit(values) {
      if (
        values.pcn !== context.pcnValidationResponse.pcnCode ||
        values.vrm !== context.pcnValidationResponse.vrmCode ||
        !context.pcnValidationResponse.isAlreadyValidated
      ) {
        context.pcnValidationResponse.isAlreadyValidated = false;
        (async () => {
          try {
            const result: IPcnValidationResponse = await apiRequest(EcpApiMethod.POST, `${api.pcnValidation}`, values);

            if (result.pcnCode === null && result.vrmCode === null) {
              setError(true);
              setErrorMessage("The details you entered were not recognised. Please check and try again.");
            } else {
              context.pcnValidationResponse = result;
              context.pcnValidationResponse.isAlreadyValidated = true;

              !result.pcnCancelled && !result.withThirdPartyDebt && result.chargeAmt === 0
                ? props.stepUpdate(PayPcnWizardStep.NotProcessed)
                : !result.withThirdPartyDebt && (result.pcnCancelled || result.chargeAmt <= 0)
                ? props.stepUpdate(PayPcnWizardStep.Cancelled)
                : result.withThirdPartyDebt
                ? props.stepUpdate(PayPcnWizardStep.ThirdParty)
                : props.stepUpdate(PayPcnWizardStep.Ok);
            }
          } catch (error) {
            setError(true);
            setErrorMessage("Sorry. We are unable to to process your payment at the moment. Please try again later.");
          }
        })();
      } else {
        context.pcnValidationResponse.pcnCancelled
          ? props.stepUpdate(PayPcnWizardStep.Cancelled)
          : context.pcnValidationResponse.withThirdPartyDebt
          ? props.stepUpdate(PayPcnWizardStep.ThirdParty)
          : props.stepUpdate(PayPcnWizardStep.Ok);
      }
    },
  });

  return (
    <div>
      <Form action="" method="" className="pcn-form" onSubmit={handleSubmit} autoComplete="false">
        <div className="pcn-info">
          <h2>How to pay your Parking Charge Notice online</h2>
          <p>
            Enter the Parking Charge Notice number and your Vehicle Registration Number. If either is invalid, you will NOT be paying for
            your notice and you will receive further correspondence.
          </p>
        </div>
        <FormGroup row className="pcn-verification-form">
          <Col xs="12" md="3">
            <Label htmlFor="pcn" className="pcn-label">
              PCN number
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="pcn"
              onChange={handleChange}
              value={values.pcn}
              disabled={context.appeal.appealSubmitted}
              className={!touched.pcn ? "" : errors.pcn ? "is-invalid" : "is-valid"}
            />
            <FormFeedback>{errors.pcn ? errors.pcn : null}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="vrm" className="pcn-label">
              Vehicle registration
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="vrm"
              onChange={handleChange}
              value={values.vrm}
              disabled={context.appeal.appealSubmitted}
              className={!touched.vrm ? "" : errors.vrm ? "is-invalid" : "is-valid"}
            />
            <FormFeedback>{errors.vrm ? errors.vrm : null}</FormFeedback>
          </Col>
        </FormGroup>
        {error && <AlertMessage color="danger" message={errorMessage}></AlertMessage>}
        <div className="pcn-info mt-5">
          <p>Please check that the details entered are correct.</p>
        </div>
        <FormButtons isProcessing={isProcessing} hidePrevious={true} />
      </Form>
      {isProcessing && <div className="pcn-spinner"></div>}
    </div>
  );
};

export default Verification;
