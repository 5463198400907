const apiConfig = {
  resourceUri: "https://localhost:5021",
};

const devConfig = {
  apiConfig,
  dataTokenizationKey: "7swqAn-VfeNk5-4w4B3B-BEhGXd",
};

export default devConfig;
