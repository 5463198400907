import { useContext, useEffect, useRef, useState } from "react";
import { Form, FormGroup, Col, Label, Input, FormFeedback } from "reactstrap";
import { AppealWizardStep } from "../../enums/AppealWizardStep";
import { useFormik } from "formik";
import { AppContext } from "../../contexts/app-context";
import FormButtons from "../../components/FormButtons";
import EvidenceFiles from "./EvidenceFiles";

const AppealEvidence = (props: { stepUpdate: (value: AppealWizardStep) => void }) => {
  const context = useContext(AppContext);

  const fileRef = useRef<any>(null);
  const effectCalled = useRef(false);

  const [refreshFilesDetails, setRefreshFilesDetails] = useState(false);
  const [evidenceFiles, setEvidenceFiles] = useState<Array<any>>([]);
  const [filesNotSelected, setFilesNotSelected] = useState(true);
  const [showFileNotSelectedError, setShowFileNotSelectedError] = useState(false);
  const [fileExistsError, setFileExistsError] = useState(false);
  const [maxSizeError, setMaxSizeError] = useState(false);
  const [wrongFormatError, setWrongFormatError] = useState(false);

  const [noFilesConfirmed, setNoFilesConfirmed] = useState(false);
  
  useEffect(() => {
    // only execute the effect first time around
    if (!effectCalled.current) {
      effectCalled.current = true;

      // populates array with files from context to display the selected files on screen from previous attempts
      let auxArray: any[] = [];
      if (context.appeal.evidenceFiles !== undefined) {
        context.appeal.evidenceFiles.forEach((element) => {
          if (element !== undefined) auxArray.push(element);
        });
        setEvidenceFiles(auxArray);
        setNoFilesConfirmed(false);
        if (context.appeal.evidenceFiles.length > 0) setFilesNotSelected(false);
      }
    }
  }, []);

  const allEvidencesAreSubmitted = (evidenceFiles: any[], filesSubmitted: string[]) => {
    return (
      Array.isArray(evidenceFiles) &&
      Array.isArray(filesSubmitted) &&
      evidenceFiles.length === filesSubmitted.length &&
      evidenceFiles.every((val, index) => val.name === filesSubmitted[index])
    );
  };

  const nextStep = () => {
    context.appeal.evidenceFiles = [];
    evidenceFiles.forEach((element) => {
      context.appeal.evidenceFiles.push(element);
    });
    props.stepUpdate(AppealWizardStep.Review);
  };

  const { handleSubmit, handleChange, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      appealFiles: false,
    },
    onSubmit() {
      if (((!filesNotSelected && evidenceFiles.length > 0) || noFilesConfirmed) && allEvidencesAreSubmitted(evidenceFiles, context.appeal.filesSubmitted)) {
        context.appeal.processCompleted = true;
        nextStep();
      } else {
        if (filesNotSelected) {
          setShowFileNotSelectedError(true);
        } else {
          nextStep();
        }
      }
    },
  });

  const fileAlreadyAdded = (fileName: string) => {
    const found = evidenceFiles.find((element: any) => element.name === fileName);
    return found !== undefined;
  };

  const handleFileChange = (e: any) => {
    setFileExistsError(false);
    setWrongFormatError(false);
    setShowFileNotSelectedError(false);
    setMaxSizeError(false);

    if (e.target.files[0].type !== "image/jpeg" && e.target.files[0].type !== "application/pdf") {
      setWrongFormatError(true);
    } else if (e.target.files[0].size > 4194304) {
      setMaxSizeError(true);
    } else if (fileAlreadyAdded(e.target.files[0].name)) {
      setFileExistsError(true);
    } else {
      setEvidenceFiles([...evidenceFiles, e.target.files[0]]);
      // for validation minimum one file selected.
      setFilesNotSelected(false);
      setShowFileNotSelectedError(false);
      setMaxSizeError(false);
    }
  };

  const handleAddButton = () => {
    // `current` points to the mounted element
    fileRef.current.click();
  };

  const handleRemoveFile = (value: any) => {
    context.appeal.processCompleted = false;
    const auxArray = evidenceFiles;
    auxArray.splice(value, 1);
    setEvidenceFiles(auxArray);
    setRefreshFilesDetails(!refreshFilesDetails);
    // for validation minimum one file selected.
    if (evidenceFiles.length === 0) {
      setFileExistsError(false);
      setFilesNotSelected(true);
      if (touched.appealFiles) setShowFileNotSelectedError(true);
    } else {
      setFilesNotSelected(false);
    }
  };

  const handleNoFilesConfirmedChange = () =>
  {
    setNoFilesConfirmed(!noFilesConfirmed);
  }

  return (
    <div>
      <Form action="" method="" className="pcn-form" onSubmit={handleSubmit}>
        <div className="pcn-info">
          <h2>Add supporting information files</h2>
          <p>
            Please attach any relevant supporting information to assist with this appeal, this may include a store receipt from the day in
            question, proof of purchases via a bank statement, full copy of a Disabled Badge, pay and display ticket / permit purchased,
            etc.
          </p>
          <p>
            If you have supporting information which you cannot attach using the "Add" button below, then please send a written appeal to:
          </p>
          <ul className="pcn-address">
            <li>Parking Charge Notice Department</li>
            <li>30 Dorset Square</li>
            <li>London</li>
            <li>NW1 6QJ</li>
          </ul>
        </div>
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="reason" className="pcn-label">
              Supporting information
            </Label>
          </Col>
          <Col xs="12" md="9">
            <input
              type="file"
              name="appealFiles"
              ref={fileRef}
              onChange={(e) => {
                handleChange(e);
                handleFileChange(e);
              }}
              accept=".jpg,.jpeg,.pdf"
              className="d-none"
            />
            <Input
              type="text"
              placeholder="Select files"
              className={"pcn-form-evidence-files " + (!touched.appealFiles ? "" : (showFileNotSelectedError && !noFilesConfirmed) ? "is-invalid" : "is-valid")}
              disabled={true}
            />
             {showFileNotSelectedError && !noFilesConfirmed && <FormFeedback className="d-block ms-0">Supporting evidence must be attached or confirm below if not available</FormFeedback>}
            {!showFileNotSelectedError && fileExistsError && (
              <FormFeedback className="d-block ms-0">
                A file with the same name was already added, please select a different file
              </FormFeedback>
            )}
            {!showFileNotSelectedError && maxSizeError && (
              <FormFeedback className="d-block ms-0">The maximum file size is 4MB</FormFeedback>
            )}
            {!showFileNotSelectedError && wrongFormatError && (
              <FormFeedback className="d-block ms-0">Only JPG images and PDF files are allowed</FormFeedback>
            )}
            <Input
              type="button"
              className="pcn-btn pcn-btn-inline pcn-add-evicence-files"
              value="Add"
              onClick={handleAddButton}
              disabled={evidenceFiles.length > 4}
            />
            <EvidenceFiles
              files={evidenceFiles}
              refreshFilesDetails={refreshFilesDetails}
              removeFile={(value) => handleRemoveFile(value)}
            />
          </Col>
        </FormGroup>
        <div className="pcn-info">
          <p>A maximum of 5 files can be attached, each file having a maximum file size of 4 Mb. Allowed file types are jpg or pdf.</p>
        </div>

        {filesNotSelected && (
          <>
            <div className="pcn-info">
              <h2>Nothing to attach?</h2>
              <p>
                <b>Any supporting evidence will allow us to process your appeal efficiently. Please ensure that you provide ALL the information before submitting your appeal.</b>
              </p>
            </div>
            <FormGroup row>
              <Col className="mb-2 mb-md-0">
                <Input
                  type="checkbox"
                  name="noAttachments"
                  checked={noFilesConfirmed}
                  onChange={handleNoFilesConfirmedChange}
                  className={(!touched.appealFiles ? "" : (showFileNotSelectedError && !noFilesConfirmed) ? "is-invalid" : "is-valid")}
                />
                <Label for="reply" className="ms-2">
                  I do not have any supporting information.
                </Label>
              </Col>
            </FormGroup>            
          </>
        )}
        <FormButtons isProcessing={false} previousStep={() => props.stepUpdate(AppealWizardStep.Reason)} />
      </Form>
    </div>
  );
};

export default AppealEvidence;
