import { useContext, useEffect, useState } from "react";
import { AppContext, clearPcnValidationResponseContext } from "../../contexts/app-context";
import { AppealWizardStep } from "../../enums/AppealWizardStep";
import { DebtRecoveryAgent } from "../../enums/DebtRecoveryAgent";
import { Form } from "reactstrap";
import FormRowView from "../../components/FormRowView";
import FormButtons from "../../components/FormButtons";

const AppealThirdParty = (props: { stepUpdate: (value: AppealWizardStep) => void }) => {
  const context = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true);

  const [thirdPartyName, setThirdPartyName] = useState("");
  const [thirdPartyUrl, setThirdPartyUrl] = useState("");
  const [thirdPartyTelephone, setThirdPartyTelephone] = useState("");

  useEffect(() => {
    switch (context.pcnValidationResponse.thirdPartyAgentId) {
      case DebtRecoveryAgent.DRPL:
        setThirdPartyName("Debt Recovery Plus");
        setThirdPartyUrl("https://www.debtrecoveryplus.co.uk/");
        setThirdPartyTelephone("");
        break;
      case DebtRecoveryAgent.DCBL:
        setThirdPartyName("DCBL");
        setThirdPartyUrl("https://www.dcbltd.com/pay-online/");
        setThirdPartyTelephone("0203 434 0428");
        break;
      case DebtRecoveryAgent.DCBL_L:
        setThirdPartyName("DCB Legal");
        setThirdPartyUrl("http://www.dcblegal.co.uk/");
        setThirdPartyTelephone("0203 838 7038");
        break;
      case DebtRecoveryAgent.SCS:
        setThirdPartyName("SCS Law");
        setThirdPartyUrl("https://www.scs-law.co.uk/");
        setThirdPartyTelephone("");
        break;
      case DebtRecoveryAgent.EuroParkingCollections:
        setThirdPartyName("Euro Parking Collections");
        setThirdPartyUrl("https://www.epcplc.com/");
        setThirdPartyTelephone("");
        break;
      case DebtRecoveryAgent.CST:
        setThirdPartyName("CST Law");
        setThirdPartyUrl("https://www.creditstyle.co.uk/pay/");
        setThirdPartyTelephone("");
        break;
      case DebtRecoveryAgent.GordonAndNoble:
        setThirdPartyName("Gordon & Noble");
        setThirdPartyUrl("https://www.gordonnoble.com/map");
        setThirdPartyTelephone("0141 221 8615");
        break;
      case DebtRecoveryAgent.ZZPS_L:
        setThirdPartyName("ZZPS L");
        setThirdPartyUrl("https://paymyparkingcharge.com/#/login");
        setThirdPartyTelephone("");
        break;
      case DebtRecoveryAgent.ZZPS_DWL:
        setThirdPartyName("ZZPS DWL");
        setThirdPartyUrl("https://paymyparkingcharge.com/#/login");
        setThirdPartyTelephone("");
        break;
      case DebtRecoveryAgent.ZZPS_DNL:
        setThirdPartyName("ZZPS DNL");
        setThirdPartyUrl("https://paymyparkingcharge.com/#/login");
        setThirdPartyTelephone("");
        break;
      default:
        setThirdPartyName(context.pcnValidationResponse.thirdPartyAgent);
        setThirdPartyUrl("https://www.google.com/search?q=" + context.pcnValidationResponse.thirdPartyAgent);
        break;
    }
    setIsLoading(false);
  }, []);

  return (
    <div>
      {!isLoading && (
        <Form className="pcn-form row">
          <div className="pcn-info">
            <h2>PCN referred to {thirdPartyName}</h2>
          </div>
          <FormRowView label="PCN number" value={context.pcnValidationResponse.pcnCode} />
          <FormRowView label="Vehicle registration" value={context.pcnValidationResponse.vrmCode} />
          <div className="pcn-info">
            <p>
              Please be advised that your Parking Charge Notice has been referred to {thirdPartyName}. Appeal has to be made with{" "}
              {thirdPartyName} and cannot be accepted by Euro Car Parks.
            </p>
            <p>
              You are now required to contact {thirdPartyName} directly. Please{" "}
              {thirdPartyTelephone !== "" ? "call " + thirdPartyTelephone + " or " : ""} visit the {thirdPartyName} web site{" "}
              <b>
                <a href={thirdPartyUrl} target="_blank">
                  {thirdPartyUrl}
                </a>
              </b>
            </p>
          </div>
          <FormButtons
            isProcessing={false}
            previousStep={() => props.stepUpdate(AppealWizardStep.Verification)}
            nextStep={() => {
              clearPcnValidationResponseContext();
              props.stepUpdate(AppealWizardStep.Verification);
            }}
            nextText="Start Again"
          ></FormButtons>
        </Form>
      )}
    </div>
  );
};

export default AppealThirdParty;
