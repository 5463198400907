import { Col, Row } from "reactstrap";

const FormButtons = (props: {
  previousStep?: () => void;
  nextStep?: () => void;
  isProcessing: boolean;
  hidePrevious?: boolean;
  hideNext?: boolean;
  displaySubmit?: boolean;
  nextText?: string;
  previousText?: string;
}) => {
  const nextText = props.nextText ?? "Continue";
  const previousText = props.previousText ?? "Go Back";

  const next = () => {
    return (
      <input
        type={props.nextStep ? "button" : "submit"}
        className="pcn-btn pcn-btn-primary float-end"
        disabled={props.isProcessing}
        value={nextText}
        onClick={props.nextStep}
      />
    );
  };

  const previous = () => {
    return (
      <input
        type="reset"
        className="pcn-btn pcn-btn-secondary float-start"
        disabled={props.isProcessing}
        value={previousText}
        onClick={props.previousStep}
      />
    );
  };

  const submit = () => {
    return (
      <Col>
        <input type="submit" className="pcn-btn pcn-btn-primary float-end" disabled={props.isProcessing} value={nextText} />
      </Col>
    );
  };

  return (
    <>
      {/* All sizes but mobile devices. It will be hidden for <768px */}
      <div className="d-none d-md-block mt-3">
        <Row>
          <Col>{!props.hidePrevious && previous()}</Col>
          <Col></Col>
          <Col>
            {!props.displaySubmit && !props.hideNext && next()}
            {props.displaySubmit && submit()}
          </Col>
        </Row>
      </div>

      {/* Mobile devices. It will be hidden for >=768px */}
      <div className="d-block d-md-none">
        <Row>
          <Col>
            {props.displaySubmit && submit()}
            {!props.displaySubmit && !props.hideNext && next()}
            {!props.hidePrevious && previous()}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FormButtons;
