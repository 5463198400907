import { AppealWizardStep } from "../../enums/AppealWizardStep";
import AppealNotValidResponse from "./AppealNotValidResponse";

const AppealPopla = (props: { stepUpdate: (value: AppealWizardStep) => void }) => {
  const handleStepUpdate = (value: AppealWizardStep) => {
    props.stepUpdate(value);
  };

  return (
    <AppealNotValidResponse
      headerText="Appeal at POPLA"
      bodyText="We can no longer accept your appeal as the PCN is now at POPLA."
      stepUpdate={handleStepUpdate}
    ></AppealNotValidResponse>
  );
};

export default AppealPopla;
