import { Col, FormGroup } from "reactstrap";

const FormRowView = (props: { label: string; value: string }) => {
  return (
    <FormGroup row>
      <Col xs="12" md="3">
        <span className="pcn-label">{props.label}</span>
      </Col>
      <Col xs="12" md="9">
        <span className="pcn-review-detail">{props.value}</span>
      </Col>
    </FormGroup>
  );
};

export default FormRowView;
