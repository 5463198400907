import { AppealWizardStep } from "../../enums/AppealWizardStep";
import AppealNotValidResponse from "./AppealNotValidResponse";

const AppealExists = (props: { stepUpdate: (value: AppealWizardStep) => void }) => {
  const handleStepUpdate = (value: AppealWizardStep) => {
    props.stepUpdate(value);
  };

  return (
    <AppealNotValidResponse
      headerText="Appeal already exists"
      bodyText="The Parking Charge Notice has an appeal already in progress. You will be advised in due course of the outcome of your appeal via email."
      stepUpdate={handleStepUpdate}
    ></AppealNotValidResponse>
  );
};

export default AppealExists;
