import { Form, FormGroup, Col, Input, FormFeedback } from "reactstrap";
import { AppealWizardStep } from "../../enums/AppealWizardStep";
import { useContext } from "react";
import { AppContext } from "../../contexts/app-context";
import { useFormik } from "formik";
import FormButtons from "../../components/FormButtons";
import * as Yup from "yup";

const AppealReason = (props: { stepUpdate: (value: AppealWizardStep) => void }) => {
  const context = useContext(AppContext);

  const validationSchema = Yup.object({
    reason: Yup.string().required("Please tell us the reasons for your appeal"),
  });

  const { handleSubmit, handleChange, touched, values, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      reason: context.appeal.reason,
    },
    validationSchema,
    onSubmit(values) {
      context.appeal.reason = values.reason;

      props.stepUpdate(AppealWizardStep.Evidence);
    },
  });

  return (
    <div>
      <Form action="" method="" className="pcn-form" onSubmit={handleSubmit}>
        <div className="pcn-info">
          <h2>Reason for appeal</h2>
          <p>Please tell us the reasons for your appeal using the box below with any relevent details you have to assist us.</p>
          <p>On the next screen you will be able to upload supporting information, such as photos, receipts or proof of purchase.</p>
        </div>
        <FormGroup row>
          <Col xs="12" md="12" className="mb-2 mb-md-0">
            <Input
              type="textarea"
              name="reason"
              rows="5"
              maxLength={800}
              onChange={handleChange}
              value={values.reason}
              disabled={context.appeal.appealSubmitted}
              className={"pcn-textarea " + (!touched.reason ? "" : errors.reason ? "is-invalid" : "is-valid")}
            />
            <FormFeedback>{errors.reason ? errors.reason : null}</FormFeedback>
          </Col>
        </FormGroup>
        <FormButtons isProcessing={false} previousStep={() => props.stepUpdate(AppealWizardStep.PersonalDetails)} />
      </Form>
    </div>
  );
};

export default AppealReason;
