import { useContext } from "react";
import { AppContext } from "../../contexts/app-context";
import { Form, FormGroup, Col, Label, Input, FormFeedback } from "reactstrap";
import { IPcnValidationResponse } from "../../models/IPcnValidationResponse";
import { AppealWizardStep } from "../../enums/AppealWizardStep";
import { useFormik } from "formik";
import { EcpApiMethod } from "../../enums/ApiMethod";
import useApiRequest from "../../hooks/useApiRequest";
import FormButtons from "../../components/FormButtons";
import AlertMessage from "../../components/AlertMessage";
import * as Yup from "yup";
import * as api from "../../api/api-urls";

const Verification = (props: { stepUpdate: (value: AppealWizardStep) => void }) => {
  const context = useContext(AppContext);

  const { apiRequest, isProcessing, error, setError, errorMessage, setErrorMessage } = useApiRequest();

  const validationSchema = Yup.object({
    pcn: Yup.string().nullable().required("PCN number is required"),
    vrm: Yup.string().nullable().required("Vehicle registration is required"),
  });

  const { handleSubmit, handleChange, touched, values, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      pcn: context.pcnValidationResponse.pcnCode !== undefined ? context.pcnValidationResponse.pcnCode : "",
      vrm: context.pcnValidationResponse.vrmCode !== undefined ? context.pcnValidationResponse.vrmCode : "",
    },
    validationSchema,
    onSubmit(values) {
      if (
        values.pcn !== context.pcnValidationResponse.pcnCode ||
        values.vrm !== context.pcnValidationResponse.vrmCode ||
        !context.pcnValidationResponse.isAlreadyValidated
      ) {
        context.pcnValidationResponse.isAlreadyValidated = false;
        (async () => {
          try {
            const result: IPcnValidationResponse = await apiRequest(EcpApiMethod.POST, `${api.pcnValidation}`, values);

            if (result.pcnCode === null && result.vrmCode === null) {
              handleError("The details you entered were not recognised. Please check and try again.");
            } else {
              context.pcnValidationResponse.pcnCode = result.pcnCode;
              context.pcnValidationResponse.vrmCode = result.vrmCode;
              context.pcnValidationResponse.pcnDate = result.pcnDate;
              context.pcnValidationResponse.pcnTime = result.pcnTime;
              context.pcnValidationResponse.chargeAmt = result.chargeAmt;
              context.pcnValidationResponse.thirdPartyAgentId = result.thirdPartyAgentId;
              context.appeal.accountId = result.debtId;

              if (result.appealExists) {
                props.stepUpdate(AppealWizardStep.Exists);
              } else if (!result.appealAllowed) {
                props.stepUpdate(AppealWizardStep.NotAllowed);
              } else if (result.isPopla) {
                props.stepUpdate(AppealWizardStep.Popla);
              } else {
                if (result.pcnCancelled) {
                  props.stepUpdate(AppealWizardStep.Cancelled);
                } else if (result.withThirdPartyDebt) {
                  props.stepUpdate(AppealWizardStep.ThirdParty);
                } else {
                  context.pcnValidationResponse.isAlreadyValidated = true;
                  props.stepUpdate(AppealWizardStep.ContactInformation);
                }
              }
            }
          } catch (error) {
            handleError("Sorry. We are unable to to process your request at the moment. Please try again later.");
          }
        })();
      } else {
        context.pcnValidationResponse.pcnCancelled
          ? props.stepUpdate(AppealWizardStep.Cancelled)
          : props.stepUpdate(AppealWizardStep.ContactInformation);
      }
    },
  });

  const handleError = (message: string) => {
    setError(true);
    setErrorMessage(message);
  };

  return (
    <div>
      <Form action="" method="" className="pcn-form" onSubmit={handleSubmit}>
        <div className="pcn-info">
          <h2>How to appeal your parking charge notice online</h2>
          <p>
            Please review our FAQs before submitting an appeal. Enter the parking charge notice (PCN) number and Vehicle Registration
            number. If either number is entered incorrectly, you will NOT be appealing your notice and you will not receive further
            correspondence.
          </p>
        </div>
        {/* <Row>
          <Col xs="1" sm="2" md="2" xl="2"></Col>
          <Col xs="10" sm="8" md="8" xl="8">
            <FormGroup row className="pcn-verification-form">
              <Col xs="12" md="6" lg="5" xl="4" xxl="4">
                <Label htmlFor="pcn" className="pcn-label">
                  PCN number
                </Label>
              </Col>
              <Col xs="12" md="6" lg="7" xl="6" xxl="5">
                <Input
                  type="text"
                  name="pcn"
                  onChange={handleChange}
                  value={values.pcn}
                  disabled={context.appeal.appealSubmitted}
                  className={!touched.pcn ? "" : errors.pcn ? "is-invalid" : "is-valid"}
                />
                <FormFeedback>{errors.pcn ? errors.pcn : null}</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs="12" md="6" lg="5" xl="4" xxl="4">
                <Label htmlFor="vrm" className="pcn-label">
                  Vehicle registration
                </Label>
              </Col>
              <Col xs="12" md="6" lg="7" xl="6" xxl="5">
                <Input
                  type="text"
                  name="vrm"
                  onChange={handleChange}
                  value={values.vrm}
                  disabled={context.appeal.appealSubmitted}
                  className={!touched.vrm ? "" : errors.vrm ? "is-invalid" : "is-valid"}
                />
                <FormFeedback>{errors.vrm ? errors.vrm : null}</FormFeedback>
              </Col>
            </FormGroup>
          </Col>
        </Row> */}

        <FormGroup row className="pcn-verification-form">
          <Col xs="12" md="3">
            <Label htmlFor="pcn" className="pcn-label">
              PCN number
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="pcn"
              onChange={handleChange}
              value={values.pcn}
              disabled={context.appeal.appealSubmitted}
              className={!touched.pcn ? "" : errors.pcn ? "is-invalid" : "is-valid"}
            />
            <FormFeedback>{errors.pcn ? errors.pcn : null}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="vrm" className="pcn-label">
              Vehicle registration
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="vrm"
              onChange={handleChange}
              value={values.vrm}
              disabled={context.appeal.appealSubmitted}
              className={!touched.vrm ? "" : errors.vrm ? "is-invalid" : "is-valid"}
            />
            <FormFeedback>{errors.vrm ? errors.vrm : null}</FormFeedback>
          </Col>
        </FormGroup>
        {error && <AlertMessage color="danger" message={errorMessage}></AlertMessage>}
        <FormButtons isProcessing={isProcessing} hidePrevious={true} />
      </Form>
      {isProcessing && <div className="pcn-spinner"></div>}
    </div>
  );
};

export default Verification;
