import { useState } from "react";
import { PayPcnWizardStep } from "../../enums/PayPcnWizardStep";
import Verification from "./Verification";
import PayParkingClosed from "./PayParkingClosed";
import PayParkingInfo from "./PayParkingInfo";
import PayOnline from "./PayOnline";
import PayByCheque from "./PayByCheque";
import PaySuccess from "./PaySuccess";
import PayThirdParty from "./PayThirdParty";
import PayParkingNotProcessed from "./PayParkingNotProcessed";

const PayPcnWizard = () => {
  const [step, setStep] = useState(PayPcnWizardStep.Verification);

  const handleStepUpdate = (value: PayPcnWizardStep) => {
    setStep(value);
  };

  return (
    <div>
      {step === PayPcnWizardStep.Verification && <Verification stepUpdate={handleStepUpdate} />}
      {step === PayPcnWizardStep.NotProcessed && <PayParkingNotProcessed stepUpdate={handleStepUpdate} />}
      {step === PayPcnWizardStep.Cancelled && <PayParkingClosed stepUpdate={handleStepUpdate} />}
      {step === PayPcnWizardStep.ThirdParty && <PayThirdParty stepUpdate={handleStepUpdate} />}
      {step === PayPcnWizardStep.Ok && <PayParkingInfo stepUpdate={handleStepUpdate} />}
      {step === PayPcnWizardStep.PayOnline && <PayOnline stepUpdate={handleStepUpdate} />}
      {step === PayPcnWizardStep.PayCheque && <PayByCheque stepUpdate={handleStepUpdate} />}
      {step === PayPcnWizardStep.PaySuccess && <PaySuccess stepUpdate={handleStepUpdate} />}
    </div>
  );
};

export default PayPcnWizard;
