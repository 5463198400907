import { useContext } from "react";
import { Form } from "reactstrap";
import { AppContext } from "../../contexts/app-context";
import { AppealWizardStep } from "../../enums/AppealWizardStep";
import FormButtons from "../../components/FormButtons";
import FormRowView from "../../components/FormRowView";

const AppealNotValidResponse = (props: { headerText: string; bodyText: string; stepUpdate: (value: AppealWizardStep) => void }) => {
  const context = useContext(AppContext);

  return (
    <div>
      <Form action="" method="" className="pcn-form row">
        <div className="pcn-info">
          <h2>{props.headerText}</h2>
        </div>
        <FormRowView label="PCN number" value={context.pcnValidationResponse.pcnCode} />
        <FormRowView label="Vehicle registration" value={context.pcnValidationResponse.vrmCode} />
        <FormRowView label="Date" value={context.pcnValidationResponse.pcnDate} />
        <FormRowView label="Time" value={context.pcnValidationResponse.pcnTime} />

        <div className="pcn-info">
          <p>{props.bodyText}</p>
        </div>
        <FormButtons previousStep={() => props.stepUpdate(AppealWizardStep.Verification)} isProcessing={false} hideNext={true} />
      </Form>
    </div>
  );
};

export default AppealNotValidResponse;
