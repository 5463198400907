import { AppealWizardStep } from "../../enums/AppealWizardStep";
import AppealNotValidResponse from "./AppealNotValidResponse";

const AppealClosed = (props: { stepUpdate: (value: AppealWizardStep) => void }) => {
  const handleStepUpdate = (value: AppealWizardStep) => {
    props.stepUpdate(value);
  };

  return (
    <AppealNotValidResponse
      headerText="Appeal closed"
      bodyText="It is not possible to appeal this PCN. This may be because an appeal has already been registered; or the PCN has been closed."
      stepUpdate={handleStepUpdate}
    ></AppealNotValidResponse>
  );
};

export default AppealClosed;
