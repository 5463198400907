import { useContext } from "react";
import { AppContext } from "../../contexts/app-context";
import { Form, FormGroup, Col, Label, Input, FormFeedback, Row } from "reactstrap";
import { AppealWizardStep } from "../../enums/AppealWizardStep";
import { useFormik } from "formik";
import FormRowView from "../../components/FormRowView";
import FormButtons from "../../components/FormButtons";
import * as Yup from "yup";

const AppealContactInformation = (props: { stepUpdate: (value: AppealWizardStep) => void }) => {
  const context = useContext(AppContext);

  const validationSchema = Yup.object({
    email: Yup.string().required("Please enter an email address").email("Please check your email address is correct"),
    confirmEmail: Yup.string()
      .required("Please confirm your email address")
      .email("Please check your email address is correct")
      .oneOf([Yup.ref("email"), null], "Your confirmation email must match the email above"),
  });

  const { handleSubmit, handleChange, touched, values, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: context.appeal.email,
      confirmEmail: context.appeal.email,
    },
    validationSchema,
    onSubmit(values) {
      context.appeal.email = values.email;

      props.stepUpdate(AppealWizardStep.PersonalDetails);
    },
  });

  const renderPayNowButton = () => {
    return (
      <a href="/pay-a-parking-charge/" className="pcn-btn pcn-btn-primary pay-online-link float-end" target="_top">
        {"Pay now - ??" + context.pcnValidationResponse.chargeAmt.toFixed(2)}
      </a>
    );
  };

  return (
    <div>
      <Form action="" method="" className="pcn-form" onSubmit={handleSubmit}>
        <div className="pcn-info">
          <h2>Contact information</h2>
          <p>
            Please confirm the PCN details you provided and then enter your contact details in the form to make your appeal now.{" "}
            <b>
              The information you enter is solely used for registering and contacting you about your appeal and will not be used for any
              other purpose.
            </b>
          </p>
        </div>
        <br />
        <FormRowView label="PCN number" value={context.pcnValidationResponse.pcnCode} />
        <FormRowView label="Vehicle registration" value={context.pcnValidationResponse.vrmCode} />
        <br />
        <FormRowView label="Date" value={context.pcnValidationResponse.pcnDate} />
        <FormRowView label="Time" value={context.pcnValidationResponse.pcnTime} />
        <FormRowView label="Parking charge" value={"??" + context.pcnValidationResponse.chargeAmt.toFixed(2)} />
        <br />
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="email" className="pcn-label">
              Email address
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="email"
              placeholder="Your contact email address"
              onChange={handleChange}
              value={values.email}
              disabled={context.appeal.appealSubmitted}
              className={!touched.email ? "" : errors.email ? "is-invalid" : "is-valid"}
            />
            <FormFeedback>{errors.email ? errors.email : null}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="3">
            <Label htmlFor="confirmEmail" className="pcn-label">
              Confirm email address
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="confirmEmail"
              placeholder="Your contact email address"
              onChange={handleChange}
              value={values.confirmEmail}
              disabled={context.appeal.appealSubmitted}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              className={!touched.confirmEmail ? undefined : errors.confirmEmail ? "is-invalid" : "is-valid"}
            />
            <FormFeedback>{errors.confirmEmail ? errors.confirmEmail : null}</FormFeedback>
          </Col>
        </FormGroup>
        <Row>
          {/* All sizes but mobile devices. It will be hidden for <576px */}
          <div className="d-none d-md-block mt-3">
            <Row>
              <Col></Col>
              <Col></Col>
              <Col>{renderPayNowButton()}</Col>
            </Row>
          </div>

          {/* Mobile devices. It will be hidden for >=576px */}
          <div className="d-block d-md-none">
            <Row>
              <Col>{renderPayNowButton()}</Col>
            </Row>
          </div>
        </Row>

        <FormButtons isProcessing={false} previousStep={() => props.stepUpdate(AppealWizardStep.Verification)} />
      </Form>
    </div>
  );
};

export default AppealContactInformation;
