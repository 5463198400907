import axios, { AxiosRequestConfig } from "axios";
import appConfig from "./../app-config/app-config-dev";

const UseEcpApi = async (method: AxiosRequestConfig["method"], url: string, item?: any, type?: string) => {
  const urlApi = appConfig.apiConfig.resourceUri + url;

  const result = await axios({
    method: method,
    url: urlApi,
    data: item,
    headers: {
      "Content-Type": type !== null ? type : "application/json",
      Accept: "application/json",
    },
  });
  return result.data;
};

export default UseEcpApi;
